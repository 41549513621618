<script lang="ts" setup>
const props = defineProps({
  show: { required: true, type: Boolean, default: false },
  persistent: { required: false, type: Boolean, default: false },
  dialogTitle: { required: false, type: String, default: '' },
  icon: { required: false },
  description: { required: false, type: String, default: '' },
  cancelText: { required: false, type: String, default: '' },
  confirmText: { required: false, type: String, default: '' },
  descriptionClass: { required: false, type: String, default: '' },
});
const emit = defineEmits(['on-cancel', 'on-confirm']);

const toggleDialog: any = ref(false);

watch(() => props.show, (value: boolean) => {
  toggleDialog.value = value;
});

watch(() => toggleDialog.value, (value: boolean) => {
  if (!value) {
    emit('on-cancel');
  }
});

onMounted(() => {
  toggleDialog.value = props.show;
});

const onConfirm = () => {
  emit('on-confirm');
};
const close = () => {
  emit('on-cancel');
};
</script>

<template>
  <v-dialog
    v-model="toggleDialog"
    width="534"
    class="confirmation-dialog"
    :persistent="persistent"
    data-testid="custom-confirmation-dialog"
  >
    <v-card>
      <div
        v-if="dialogTitle || icon"
        class="confirmation-dialog__header d-flex align-center py-8 px-10"
      >
        <component
          :is="icon"
          v-if="icon"
          class="mx-5 button-v2-image dialog-logo mb-4"
        />
        <div
          v-if="dialogTitle"
          class="text-primary-1 text-desktop-body-large"
        >
          <b>{{ dialogTitle }}</b>
        </div>
      </div>

      <div class="confirmation-dialog__body py-8 px-10 bg-gray-5 text-desktop-body-large text-black-2">
        <div
          class=" confirmation-dialog__body-description"
          :class="[descriptionClass]"
        >
          <p
            v-if="description"
            class="pa-0 ma-0"
          >
            {{ description }}
          </p>
          <slot />
        </div>

        <div
          v-if="cancelText || confirmText"
          class="dialog-actions mt-9 d-flex"
        >
          <v-btn
            v-if="cancelText"
            variant="text"
            class="bg-gray-6 text-black-2 text-desktop-body-large"
            @click="close()"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            v-if="confirmText"
            variant="text"
            class="bg-primary-1 text-gray-6 text-desktop-body-large"
            @click="onConfirm()"
          >
            {{ confirmText }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.v-dialog{
  .v-card{
    border-radius: 8px !important;
  }
}
.confirmation-dialog{
  &__header{
    flex-direction: column;
    .dialog-logo{
      width: 36px;
    }
  }
  &__body{
    .dialog-actions{
      column-gap: 21px;
      justify-content: center;
      .v-btn{
        width: 169px;
        text-align: center;
        text-transform: initial;
        box-shadow: none;
      }
    }
  }

}
</style>
